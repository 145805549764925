.bt-wizard {
    .nav {
        background: $body-bg;

        .nav-link {
            &.active {
                box-shadow: 0 0 5px 2px transparentize($primary,0.7);
            }
        }
    }
}

.pc-wizard {
    .nav {
        &.nav-tabs {
            border-bottom: 1px solid $border-color;

            .nav-item {
                margin-bottom: 0;
            }

            .nav-link {
                border-radius: 0;
                border: none;
                font-size: 16px;
                color: $body-color;
                background: no-repeat center bottom, center 100%;
                background-size: 0 100%, 100% 100%;
                transition: background 0.3s ease-out;
                background-image: linear-gradient(to top, #{$primary} 2px, rgba(255, 255, 255, 0) 2px);
                opacity: 0.7;

                i {
                    margin-right: 10px;
                }
            }

            .nav-link.active,
            .show > .nav-link {
                background-size: 100% 100%, 100% 100%;
                opacity: 1;
                color: $primary;
            }
        }
    }
}

.pc-wizard-details {
    .nav {
        background: lighten($body-bg, 2%);

        &.nav-tabs {
            border-bottom: 1px solid $border-color;

            .nav-item {
                margin-bottom: -1px;
            }

            .nav-link {
                padding: 0.8rem 2rem;
                font-size: 16px;
                color: $body-color;
                background: transparent;
                opacity: 0.7;

                i {
                    margin-bottom: 5px;
                    font-size: 20px;
                    opacity: 0.3;
                    display: block;
                    text-align: center;
                }
            }

            .nav-link.active,
            .show > .nav-link {
                background: #fff;
                opacity: 1;
                font-weight: 500;
                color: $primary;

                i {
                    opacity: 0.8;
                }
            }
        }
    }
}

.pc-wizard-subtitle {
    .nav {
        &.nav-tabs {
            border-bottom: 1px solid $border-color;

            .nav-item {
                margin-bottom: -1px;
            }

            .nav-link {
                padding: 1rem 1.5rem;
                color: $body-color;
                border: none;
                opacity: 0.7;
                display: flex;
                align-items: center;
                background: no-repeat center bottom, center 100%;
                background-size: 0 100%, 100% 100%;
                transition: background 0.3s ease-out;
                background-image: linear-gradient(to top, #{$primary} 2.5px, rgba(255, 255, 255, 0) 2.5px);

                .num-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: $border-radius;
                    margin-right: 10px;
                    background: darken($body-bg,2%);
                    color: $body-color;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }

                h6 {
                    margin-bottom: 0;
                }

                i {
                    margin-right: 14px;
                    font-size: 30px;
                    opacity: 0.3;
                    display: block;
                    text-align: center;
                }
            }

            .nav-link.active,
            .show > .nav-link {
                background-size: 100% 100%, 100% 100%;
                opacity: 1;

                i {
                    opacity: 1;
                    color: $primary;
                }

                .num-icon {
                    background: $primary;
                    color: #fff;
                }
            }
        }
    }
}

.pc-wizard-subtitle-vertical {
    .nav {
        background: lighten($body-bg, 1%);

        &.nav-tabs {
            border-bottom: 1px solid $border-color;

            .nav-item {
                margin-bottom: -1px;
            }

            .nav-link {
                padding: 1rem 1.5rem;
                border-radius: 0;
                color: $body-color;
                border: none;
                opacity: 0.7;
                display: flex;
                align-items: center;
                background: no-repeat center left, center 100%;
                background-size: 100% 0, 100% 100%;
                transition: background 0.3s ease-out;
                background-image: linear-gradient(to left, #{$primary} 2.5px, rgba(255, 255, 255, 0) 2.5px);

                .num-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: $border-radius;
                    margin-right: 10px;
                    background: darken($body-bg,2%);
                    color: $body-color;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }

                h6 {
                    margin-bottom: 0;
                }

                i {
                    margin-right: 14px;
                    font-size: 30px;
                    opacity: 0.3;
                    display: block;
                    text-align: center;
                }
            }

            .nav-link.active,
            .show > .nav-link {
                background-size: 100% 100%, 100% 100%;
                opacity: 1;

                i {
                    opacity: 1;
                    color: $primary;
                }

                .num-icon {
                    background: $primary;
                    color: #fff;
                }
            }
        }
    }
}

.tab-wizard {
    &.nav {
        .nav-item {
            .nav-link {
                background: transparentize($white,.6);
            }

            +.nav-item {
                .nav-link {
                    margin-left: 10px;
                }
            }
        }

        .nav-link.active,
        .show > .nav-link {
            background: #fff;
        }
    }
}
// =======================================================================
// SmartWizard v4.4.x
$sw-icon-size: 2.5rem !default;
$sw-icon-border-width: 2px !default;
$sw-icon-font-size: 1rem !default;
$sw-spacer: 2rem !default;

.sw-theme-default {
    box-shadow: none;

    .sw-container {
        min-height: auto;
    }

    .step-content {
        margin-top: 30px;
        padding: 10px;
        border: 0 solid $border-color;
        background-color: #FFF;
        text-align: left;
    }

    .sw-toolbar {
        background: #f9f9f9;
        border-radius: 0 !important;
        padding: 10px;
        margin-bottom: 0 !important;
    }

    .sw-toolbar-top {
        border-bottom-color: $border-color !important;
    }

    .sw-toolbar-bottom {
        background: transparent;
        border-top-color: $border-color !important;
    }

    > ul.step-anchor > li {
        position: relative;
        margin-right: 2px;

        > a {
            color: $body-color;

            &:hover {
                color: $body-color;
            }
        }

        &.clickable > a:hover,
        &.clickable > a:hover h6 {
            color: $primary !important;
        }

        > a::after {
            background: $primary;
            height: 2px;
        }

        &.active > a,
        &.active > a h6 {
            color: $primary !important;
        }

        &.done > a {
            color: $headings-color !important;

            &::after {
                background: $success;
            }
        }

        &.danger > a,
        &.danger > a h6 {
            color: $danger !important;

            &::after {
                background: $danger;
                border-left-color: scale-color($danger, $alert-border-level);
            }
        }

        &.disabled > a {
            opacity: 0.5;
        }
    }
}
// arrows

.sw-theme-arrows {
    border-radius: $border-radius;
    border: 1px solid $border-color;

    > .sw-container {
        min-height: auto;
    }

    .step-content {
        margin-top: 30px;
        border: 0 solid $border-color;
    }

    > {
        ul.step-anchor {
            border-bottom: 1px solid $border-color;
            background: $body-bg;
            border-top-right-radius: $border-radius;

            > li {
                > a {
                    color: $body-color;
                    background: $body-bg;

                    &:hover {
                        color: $body-color;
                        background: $body-bg;
                    }

                    &:after {
                        border-left: 30px solid $body-bg;
                    }

                    &:before {
                        border-left: 30px solid $body-bg;
                    }
                }

                &:first-child > a {
                    padding-left: 15px;
                }

                > a {
                    &:hover {
                        color: $body-color;
                        background: $border-color;
                        border-color: $border-color;

                        &:after {
                            border-left-color: $border-color;
                        }
                    }
                }

                &.clickable > a:hover {
                    color: $primary !important;
                    background: $info !important;
                }

                &.active > a {
                    border-color: $primary !important;
                    color: #fff !important;
                    background: $primary !important;

                    h6 {
                        color: #fff !important;
                    }

                    &:after {
                        border-left: 30px solid $primary !important;
                    }
                }

                &.done > a {
                    border-color: scale-color($primary, $alert-border-level) !important;
                    color: $primary !important;
                    background: scale-color($primary, $alert-border-level) !important;

                    h6 {
                        color: $primary !important;
                    }

                    &:after {
                        border-left: 30px solid scale-color($primary, $alert-border-level);
                    }
                }

                &.danger > a {
                    border-color: $danger !important;
                    color: #fff !important;
                    background: $danger !important;

                    &:after {
                        border-left: 30px solid $danger !important;
                    }
                }

                &.disabled > a {
                    opacity: 0.5;
                }
            }
        }
    }

    &::before {
        border: 10px solid $border-color;
        border-top: 10px solid $success;
    }
}
// circles

.sw-theme-circles {
    .step-content {
        margin-top: 30px;
    }

    .sw-toolbar-bottom {
        border-top-color: $border-color !important;
        border-bottom-color: $border-color !important;
    }

    > ul.step-anchor {
        background: #fff;

        &:before {
            background-color: $body-bg;
            border-radius: $border-radius;
        }

        > li {
            margin-left: 40px;

            > a {
                border: 2px solid $body-bg;
                background: $body-bg;
                color: $body-color;
                background: $body-bg;

                &:hover {
                    color: $body-color;
                    background: $body-bg;
                }

                > p {
                    position: relative;
                    font-size: 80%;
                    bottom: -30px;
                    color: $body-color;
                }
            }

            &.clickable > a:hover {
                color: $primary !important;
            }

            &.active > a {
                border-color: $primary;
                background: $primary;
                color: #fff;

                h6 {
                    color: #fff;
                }

                > p {
                    color: $primary;
                }
            }

            &.done > a {
                border-color: $success;
                background: $success;
                color: #fff;

                h6 {
                    color: #fff;
                }

                > p {
                    color: $success;
                }
            }

            &.danger > a {
                border-color: $danger;
                background: #fff;
                color: $danger;

                h6 {
                    color: $danger;
                }

                > small {
                    color: $danger;
                }
            }

            &.disabled > a {
                opacity: 0.5;
            }
        }
    }
}
// dots

.sw-theme-dots {
    .step-content {
        margin-top: 30px;
    }

    .sw-toolbar-top {
        border-bottom-color: $border-color !important;
    }

    .sw-toolbar-bottom {
        border-top-color: $border-color !important;
        border-bottom-color: $border-color !important;
    }

    > ul.step-anchor {
        border: 0 solid $border-color !important;

        &:before {
            background-color: $body-bg;
        }

        > li {
            > a {
                color: $body-color;

                &:before {
                    color: $primary;
                    background: $body-bg;
                }

                &:after {
                    background: $body-bg;
                }

                &:hover {
                    color: $body-color;
                }

                &:focus {
                    color: $body-color;
                }
            }

            &.clickable > a:hover {
                color: $body-color;
            }

            &.active > a,
            &.active > a h6 {
                color: $primary;

                &:after {
                    background: $primary;
                }
            }

            &.done > a,
            &.done > a h6 {
                color: $success;

                &:after {
                    background: $success;
                }
            }

            &.danger > a,
            &.danger > a h6 {
                color: $danger;

                &:after {
                    background: $danger;
                }
            }

            &.disabled > a {
                opacity: 0.5;
            }
        }
    }
}
/* Responsive CSS */
@media screen and (max-width: 768px) {
    .sw-theme-dots > ul.step-anchor {
        &:before {
            background-color: $body-bg;
        }
    }
}

.smartwizard-example {
    .nav-link {
        padding-left: calc(#{$sw-icon-size} + 15px);
    }
}

.smartwizard-example {
    &.sw-main,
    .sw-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .sw-container {
        flex-shrink: 1;
    }

    .step-anchor {
        flex-direction: row;

        &:not(.card) {
            border: 0;
        }
    }

    .step-anchor li {
        display: flex;
        flex-grow: 1;
    }

    .step-anchor li > a {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 $sw-spacer 0 ($sw-icon-size + 1.25rem);
        min-height: $sw-icon-size;
        outline: 0 !important;
        border: 0 !important;
        background: transparent !important;
        text-decoration: none;
        font-weight: 500;
        opacity: 0.4;
        cursor: default;

        &:after {
            display: none;
        }

        .small,
        small {
            font-weight: normal;
        }
    }

    .step-anchor li.active > a,
    .step-anchor li.clickable > a,
    .step-anchor li.done > a {
        opacity: 1;
    }

    .step-anchor li.active > a,
    .step-anchor li.clickable > a {
        cursor: pointer;
    }

    .step-anchor li.done > a {
        cursor: pointer;
    }

    .step-anchor li.disabled > a,
    .step-anchor li.disabled > a:hover {
        cursor: not-allowed !important;
    }

    .sw-done-icon,
    .sw-icon,
    .sw-number {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -$sw-icon-size / 2;
        width: $sw-icon-size;
        height: $sw-icon-size;
        border: $sw-icon-border-width solid;
        border-radius: 50%;
        text-align: center;
        line-height: calc(#{$sw-icon-size} - #{($sw-icon-border-width * 2)});
    }

    .sw-done-icon,
    .sw-icon {
        font-size: $sw-icon-font-size;
    }

    .sw-done-icon {
        display: none;
    }

    .step-anchor li.done .sw-done-icon {
        display: block;

        ~ .sw-icon,
        ~ .sw-number {
            display: none;
        }
    }

    .wizard-number {
        font-weight: 500;
    }

    .step-anchor li.done .sw-done-icon,
    .step-anchor li.done .sw-icon,
    .step-anchor li.done .sw-number {
        opacity: 0.4;
    }

    .step-anchor > li.active > a {
        .sw-done-icon,
        .sw-icon,
        .sw-number {
            border-color: $primary;
        }
    }

    .step-content {
        position: relative;
        display: none;
        margin: 0;
        width: 100%;
    }

    .sw-toolbar {
        justify-content: flex-end;
        padding: 0;
    }
}

.sw-theme-default {
    .step-anchor li > a {
        color: $body-color;
    }

    .step-anchor li.danger > a {
        color: map-get($theme-colors, danger) !important;
    }

    .sw-done-icon,
    .sw-icon,
    .sw-number {
        border-color: $border-color;
    }

    .step-anchor li.danger .sw-done-icon,
    .step-anchor li.danger .sw-icon,
    .step-anchor li.danger .sw-number {
        border-color: map-get($theme-colors, danger) !important;
        color: map-get($theme-colors, danger) !important;
    }
}
@media (min-width: map-get($grid-breakpoints, md)) {
    .smartwizard-vertical-left.sw-main,
    .smartwizard-vertical-right.sw-main {
        flex-direction: row;
    }

    .smartwizard-vertical-left .step-anchor,
    .smartwizard-vertical-right .step-anchor {
        align-self: flex-start;
        flex-direction: column;

        li {
            flex-grow: 0;
        }
    }

    .sw-theme-default {
        &.smartwizard-vertical-right {
            flex-direction: row-reverse;
        }

        &.smartwizard-vertical-right .step-anchor li > a {
            padding: 0 0 0 (($sw-icon-size + 1.25rem) + 2rem);
        }

        &.smartwizard-vertical-right .sw-done-icon,
        &.smartwizard-vertical-right .sw-icon,
        &.smartwizard-vertical-right .sw-number {
            left: 2rem;
        }
    }
}
// =======================================================================
// =======================================================================

.tabcontrol,
.wizard {
    display: block;
    width: 100%;
    overflow: hidden;
}

.tabcontrol a,
.wizard a {
    outline: 0;
}

.tabcontrol ul,
.wizard ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
}

.tabcontrol ul > li,
.wizard ul > li {
    display: block;
    padding: 0;
}
/* Accessibility */
.tabcontrol > .content > .title,
.tabcontrol > .steps .current-info,
.wizard > .content > .title,
.wizard > .steps .current-info {
    position: absolute;
    left: -999em;
}
/*
    Wizard
*/
.wizard {
    > .steps {
        position: relative;
        display: block;
        width: 100%;
    }

    &.vertical {
        > .steps {
            display: inline;
            float: left;
            width: 30%;
        }

        > .steps > ul > li {
            float: none;
            width: 100%;
        }

        > .content {
            display: inline;
            float: left;
            margin: 0 2.5% 0.5em;
            width: 65%;
        }

        > .actions {
            display: inline;
            float: right;
            margin: 0 2.5%;
            width: 95%;
        }

        > .actions > ul > li {
            margin: 0 0 0 1em;
        }
    }

    > {
        .steps {
            .number {
                font-size: 1.429em;
            }

            > ul > li {
                width: 25%;
                float: left;
            }
        }

        .actions > ul > li {
            float: left;
        }
    }

    > {
        .steps {
            a {
                display: block;
                width: auto;
                margin: 0 0.5em 0.5em;
                padding: 1em;
                text-decoration: none;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;

                &:active,
                &:hover {
                    display: block;
                    width: auto;
                    margin: 0 0.5em 0.5em;
                    padding: 1em;
                    text-decoration: none;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                }
            }

            .disabled a {
                background: $body-bg;
                color: $body-color;
                cursor: default;
                opacity: 0.6;

                &:active,
                &:hover {
                    background: $body-bg;
                    color: $body-color;
                    cursor: default;
                }
            }

            .current a {
                background: $primary;
                color: #fff;
                cursor: default;

                &:active,
                &:hover {
                    background: $primary;
                    color: #fff;
                    cursor: default;
                }
            }

            .done a {
                background: transparentize($primary,.8);
                color: $primary;

                &:active,
                &:hover {
                    background: transparentize($primary,.8);
                    color: $primary;
                }
            }

            .error a {
                background: $danger;
                color: #fff;

                &:active,
                &:hover {
                    background: $danger;
                    color: #fff;
                }
            }
        }

        .content {
            background: lighten($body-bg,2%);
            display: block;
            margin: 0.5em;
            min-height: 8em;
            overflow: hidden;
            position: relative;
            width: auto;
            border-radius: 5px;
        }
    }

    > {
        .content > .body {
            padding: 25px;

            ul {
                list-style: disc !important;

                > li {
                    display: list-item;
                }
            }

            > iframe {
                border: 0 none;
                width: 100%;
                height: 100%;
            }

            input {
                &.error {
                    background: rgb(251, 227, 228);
                    border-color: $danger;
                    color: $danger;
                }
            }

            label {
                display: inline-block;
                margin-bottom: 0.5em;

                &.error {
                    color: $danger;
                    display: inline-block;
                    margin-left: 1.5em;
                }
            }
        }

        .actions {
            position: relative;
            display: block;
            text-align: right;
            width: 100%;
        }
    }

    > .actions > ul {
        display: inline-block;
        text-align: right;

        > li {
            margin: 0 0.5em;
        }
    }

    > {
        .actions {
            a {
                background: $primary;
                color: #fff;
                display: block;
                padding: 0.5em 1em;
                text-decoration: none;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;

                &:active,
                &:hover {
                    background: $primary;
                    color: #fff;
                    display: block;
                    padding: 0.5em 1em;
                    text-decoration: none;
                    border-radius: 5px;
                }
            }

            .disabled a {
                background: $body-bg;
                color: #aaa;

                &:active,
                &:hover {
                    background: $body-bg;
                    color: #aaa;
                }
            }
        }
    }
    @media (max-width:768px) {
        .steps > ul > li {
            width: 100%;
        }

        &.vertical {
            > .steps,
            > .content {
                width: 100%;
            }
        }
    }
}

.pc-wizard.wizard {
    .number {
        display: none;
    }

    > .steps {
        a {
            display: flex;
            align-items: center;
            margin: 0.5em;
            position: relative;

            &:after {
                content: "";
                font: {
                    family: 'Font Awesome 5 Free';
                    weight: 900;
                    size: 20px;
                }
                position: absolute;
                right: 0;
                top: 20px;
                opacity: 0.5;
            }

            span {
                b {
                    display: block;
                }
            }

            .num-icon {
                width: 35px;
                height: 35px;
                border-radius: $border-radius;
                margin-right: 10px;
                background: $body-bg;
                color: $body-color;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            &.disabled:hover {
                background: transparent;
            }

            &:hover {
                display: flex;
            }
        }

        > ul > li:last-child a:after {
            visibility: hidden;
        }

        .disabled a {
            background: transparent;
        }

        .current {
            a {
                background: transparent;
                color: inherit;

                .num-icon {
                    background: $primary;
                    color: #fff;
                }
            }
        }

        .done a {
            background: transparent;

            .num-icon {
                background: transparentize($primary,0.8);
                color: $primary;
            }

            span {
                color: $body-color;
            }
        }
    }

    > .content {
        margin: 0;
        border-radius: 0;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
    }

    > .actions {
        padding: 15px 25px 10px;
    }

    &.vertical {
        > .actions,
        > .content {
            margin: 0;
            background: lighten($body-bg,2%);
            width: 70%;
            border-left: 1px solid $border-color;
        }

        > .content {
            padding: 25px 25px 0;
            border-radius: 0 $card-border-radius 0 0;
        }

        .actions {
            padding: 25px;
            border-radius: 0 0 $card-border-radius 0;
        }

        > .steps {
            padding: 35px 10px;

            a {
                &:after {
                    content: "";
                    right: 10px;
                }
            }

            > ul > li:last-child a:after {
                visibility: visible;
            }
        }
    }
    @media (max-width:768px) {
        > .steps a:after {
            right: 15px;
        }

        > .steps > ul > li:last-child a:after {
            visibility: visible;
        }

        &.vertical {
            > .steps {
                padding: 0;
            }

            > .content {
                padding: 0;
            }

            > .actions,
            > .content {
                width: 100%;
            }
        }
    }
}

.pc-tab-control.wizard {
    .number {
        display: none;
    }

    > .steps {
        a {
            display: flex;
            align-items: center;
            margin: 0.5em 0.5em 0;
            padding: 1em 1.6em;
            border-radius: $card-border-radius $card-border-radius 0 0;
            position: relative;
            background: rgba(255,255,255,0.4);

            span {
                color: $body-color;

                b {
                    display: block;
                }
            }

            .num-icon {
                width: 35px;
                height: 35px;
                border-radius: $border-radius;
                margin-right: 10px;
                background: $body-bg;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                .material-icons-two-tone {
                    background-color: $body-color;
                }
            }

            &.disabled:hover {
                background: transparent;
            }

            &:hover {
                display: flex;
            }
        }

        .done a:active,
        .done a:hover {
            background: rgba(255,255,255,0.4);
        }

        > ul {
            > li:last-child {
                a {
                    margin-right: 0;
                }
            }

            > li:first-child {
                a {
                    margin-left: 0;
                }
            }
        }

        > ul > li:last-child a:after {
            visibility: hidden;
        }

        .disabled a {
            background: transparent;
        }

        .current {
            a {
                background: #fff;
                color: inherit;

                .num-icon {
                    background: $primary;
                    color: #fff;

                    .material-icons-two-tone {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    > .content {
        margin: 0;
        border-radius: 0 0 $card-border-radius $card-border-radius;
        background: $card-bg;
        box-shadow: $card-shadow;
        margin-bottom: 30px;
    }

    > .actions {
        padding: 15px 25px 10px;
    }
    @media (max-width:768px) {
        > .steps a {
            margin: 0;
            border-radius: $card-border-radius;
        }
    }
}
// =======================================================================
